<template>
  <div class="add-product-container">
      <div class="container-title">
        <a-breadcrumb separator=">" class="breadcrumb">
            <a-breadcrumb-item>
            <a @click.prevent="$router.push('/index')">首页</a>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
            <a @click.prevent="$router.push(`/seller-content/store-manage?entityId=${$route.query.entityId}`)">商品管理</a>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
            商品编辑
            </a-breadcrumb-item>
        </a-breadcrumb>
      </div>

      <div class="container">
        <a-card class="add-content">
            <a-row class="content">
                <a-col :span="3" class="right">卖家名称：</a-col>
                <a-col :span="12">
                    <a-input disabled :value="seller"></a-input>
                </a-col>
                <a-col :span="9" class="font-error"><span style="margin-left: 12px">限额：{{saleamount.netAmountLimit | transition}}</span></a-col>
            </a-row>

            <a-card title="商品分类选择">
                <a-row type="flex" align="middle">
                    <!-- <a-col :span="3" class="label-left">
                        您常用的分类：
                    </a-col>
                    <a-col :span="21">
                        <a-checkable-tag :checked="true">LDPE</a-checkable-tag>
                    </a-col> -->

                    <a-col :span="3" class="label-left">
                        搜索分类：
                    </a-col>
                    <a-col :span="12">
                        <SearchSelect
                            ref="SearchSelect"
                            placeholder="请输入二级分类关键词"
                            class="SearchSelect"
                            :type="['id','catValue']"
                            :focu="false"
                            @search="secondSearch"
                            :list="searchList"
                            @select="$refs.cascader.stairnIdChange($event)"
                        ></SearchSelect>
                        <!-- <a-input-search @search="search" placeholder="请输入分类关键词查询，并选择对应分类"></a-input-search> -->
                    </a-col>

                    <a-col :span="24">
                        <Cascader ref="cascader" :term="term" :list="classifyCopy" :stairnId="classifyId.primary" :accessId="classifyId.re" @change="changeRe" @changeSwitch="changeSwitch" @filterList="searchList = $event"/>
                    </a-col>

                    <a-col :span="24">
                        <a-alert class="cascader-alert">
                            <p slot="description">
                                <b>您当前选择的商品类别是：</b>
                                {{selectClassify.levelPrimary &&(selectClassify.levelPrimary.catValue + (selectClassify.levelBy && ' > ' + selectClassify.levelBy.catValue))}}
                            </p>
                        </a-alert>
                    </a-col>
                </a-row>
            </a-card>

            <a-card class="l-card">
                <div class="title">分类属性</div>
                <a-row>
                    <a-col :span="24" v-for="(item,index) in selectClassify.property" :key="item.id">
                        <a-col :span="3" class="label-left">
                            <span class="font-error" v-if="index === 0">*</span> {{item.attrName}}：
                        </a-col>
                        <a-col :span="11">
                            <a-select show-search :value="item.value" @change="item.value=$event" style="width: 240px" :placeholder="'请选择'+item.attrName">
                                <a-select-option key="No_choice" v-if="index !== 0">
                                    暂不选择
                                </a-select-option>
                                <a-select-option v-for="(name) in item.attrValue" :key="name">
                                {{ name }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col v-if="index === 0" :span="10" style="line-height: 32px">
                            <a-switch :checked="isSpec" @click="isSpec=!isSpec" style="margin-right: 16px">
                                <a-icon slot="checkedChildren" type="check" />
                                <a-icon slot="unCheckedChildren" type="close" />
                            </a-switch>
                            <span>发票上是否显示该规格</span>
                        </a-col>
                    </a-col>
                </a-row>
            </a-card>

            <a-card class="l-card">
                <div class="title">发票信息</div>
                <a-row>
                    <a-col :span="3" class="label-left"><span class="font-error">*</span>品类名称：</a-col>
                    <a-col :span="8">
                        <a-select style="width: 100%" :value="invoiceInfo.index" @change="invoiceInfo.index=$event" placeholder="请选择品类名称">
                            <a-select-option v-for="(item,index) in invoiceInfo.list" :key="index" :disabled="item.disabled">
                                {{ item.taxClassName }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="12" style="line-height: 32px;margin-left: 32px">
                        品类编码：{{
                            invoiceInfo.list.length && invoiceInfo.index !== undefined && invoiceInfo.list[invoiceInfo.index].taxClassCode || ''
                            }}
                    </a-col>

                    <a-col :span="3" class="label-left">规格型号：</a-col>
                    <a-col :span="21" style="line-height: 32px">{{isSpec && selectClassify.property.length && selectClassify.property[0].value || ''}}</a-col>
                </a-row>
            </a-card>

            <a-card class="l-card">
                <div class="title">商品信息</div>
                <a-row>
                    <a-col :span="3" class="label-left"><span class="font-error">*</span>货品数量：</a-col>
                    <a-col :span="21">
                        <a-input-number v-model="productInfo.num" @blur="getTaxes" placeholder="请输入" style="width: 280px" :precision="infoTypeObj.value === 4 || infoTypeObj.value === 5 ? 0 : 4" @change="numChange">
                        </a-input-number>
                        <div class="input-number-slot">
                            <a-select :value="infoTypeObj.value" @change="typeChange" style="width: 70px">
                                <a-select-option v-for="(item,index) in infoTypeObj.list" :value="index+ 1" :key="index">
                                    {{item | unit}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>

                    <a-col :span="3" class="label-left"><span class="font-error">*</span>增值税：</a-col>
                    <a-col :span="21">
                        <a-select :value="taxRate.id" @change="typeTaxChange" style="width: 120px">
                                <a-select-option v-for="(item) in taxRateList" :key="item.id">
                                    {{item.policyName}}
                                </a-select-option>
                            </a-select>
                    </a-col>

                    <a-col :span="3" class="label-left" style="margin-bottom: 0;"><span class="font-error">*</span>货物总价：</a-col>
                    <a-col :span="21" style="margin-bottom: 0;">
                        <a-input-number :value="taxObj.netAmount" disabled v-if="estimateModel == 2"  style="width: 295px"></a-input-number>
                        <a-input-number v-model="productInfo.price" @blur="computeBlur" @change="estimateModelChange(1)" v-else :placeholder="`限额 ${infoTypeObj.value === 4 || infoTypeObj.value === 5 ? 98000 : saleamount.netAmountLimit || ''}`" :precision="2" style="width: 295px">
                        </a-input-number>
                        <div class="input-number-slot">
                            <span style="padding: 0 10px">元</span>
                        </div>
                        <!-- <span class="font-error" style="padding-left:30px" v-if="!(infoTypeObj.value === 4 || infoTypeObj.value === 5)">{{productInfo.price > saleamount ? `*限额${saleamount}` : ''}}</span>
                        <span class="font-error" style="padding-left:30px" v-if="infoTypeObj.value === 4 || infoTypeObj.value === 5">{{productInfo.price > 98000 ? '*限额98000' : ''}}</span> -->
                        <span style="margin-left: 32px">价税合计：</span>
                        <a-input-number :value="productInfo.totalPrices" disabled v-if="estimateModel == 1"  style="width: 295px"></a-input-number>
                        <a-input-number v-model="productInfo.price" @blur="computeBlur" @change="estimateModelChange(2)" v-else :placeholder="infoTypeObj.value === 4 || infoTypeObj.value === 5 ? '请输入' : `限额${saleamount.contractAmountLimit || ''}`" :precision="2" style="width: 295px"></a-input-number>
                        <div class="input-number-slot">
                            <span style="padding: 0 10px">元</span>
                        </div>
                    </a-col>
                    <a-col :span="3"></a-col>
                    <a-col :span="21" class="font-error">*货物总价和价税合计金额二选一输入</a-col>

                    <a-col :span="6" class="label-c">货款单价：{{unitPrice}} 元/{{typeText |unit}}</a-col>
                    <a-col :span="6" class="label-c">税款总计： {{productInfo.witholding}} 元 </a-col>
                    <a-col :span="6" class="label-c">{{taxRate.vatRate * 100 + '%'}}增值税：{{productInfo.vat}} 元</a-col>
                    <a-col :span="6" class="label-c" style="visibility: hidden">&nbsp;</a-col>
                    <a-col :span="6" class="label-c">不含税价：{{taxObj.taxableAmount}} 元</a-col>
                    <a-col :span="6" class="label-c">合同总价：{{productInfo.totalPrices}} 元</a-col>
                    <a-col :span="6" class="label-c" style="visibility: hidden">&nbsp;</a-col>
                    <a-col :span="6" class="label-c" style="visibility: hidden">&nbsp;</a-col>
                    
                    <a-col :span="3" class="label-left" style="margin-bottom: 0;">商品成本：</a-col>
                    <a-col :span="21" style="margin-bottom: 0;display:flex">
                        <div style="width: 350px">
                            <a-input-number v-model="productInfo.totalCostAmount"   style="width: 295px"></a-input-number>
                            <div class="input-number-slot" >
                                <span style="padding: 0 10px">元</span>
                            </div>
                        </div>
                        <div style="flex: 1;" ><span class="font-message">* 卖家需如实申报商品成本，该成本最终按比例用于订单成本，卖家填写此成本后即表示默认将其做为最终税务自主申报成本记录。</span>  </div>
                    </a-col>
                    

                    <a-col :span="3" class="label-left">商品照片：</a-col>
                    <a-col :span="21">
                        <input v-show="false" id="addImage" ref="addImage" alt="" class="upload" type="file" name="addImage" multiple="multiple" accept="image/png,image/jpeg,image/gif,image/jpg" @change="getImgFile"/>
                        <div class="l-flex" style="margin-top: 10px">
                            <template v-for="(item, index) in merchandiseImgList">
                                <div :key="index" class="product-img" :class="item.isMain &&'is-main'" v-if="!item.delFlag">
                                    <img :src="item.imgPath" alt="">
                                    <p class="img-config">
                                        <a-space>
                                            <a @click="isMain(index, item.isMain)">{{item.isMain ? '取消设置' : '设为主图'}}</a>
                                            <a @click="delImg(index)">删除图片</a>
                                        </a-space>
                                    </p>
                                </div>
                            </template>
                            <div class="product-img add-image" @click="selectImg">
                                <a-icon type="plus" />
                            </div>
                        </div>
                    </a-col>
                </a-row>
            </a-card>

            <a-card class="l-card">
                <div class="title">地址信息</div>
                <a-row>
                    <a-col :span="3" class="label-left">常用发货地：</a-col>
                    <a-col :span="21">
                        <a-select style="width: 530px" placeholder="请选择" @change="selectAddress">
                            <a-select-option v-for="item in usualaddress" :key="item.addrFull">
                                {{`${item.addrFull}` }}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-col :span="3" class="label-left">发货地：</a-col>
                    <a-col :span="10">
                        <a-cascader :show-search="{ filter:(inputValue, path) => path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) }"  style="width: 320px" :value="cascaderValue" :field-names="{ value: 'key', label: 'label', children: 'children' }" :options="options" @change="cascaderChange" placeholder="请选择" />
                    </a-col>
                    <a-col :span="11">
                        <a-input v-model="siteInfo.address" placeholder="请输入详细地址"></a-input>
                    </a-col>

                    <a-col :span="3" class="label-left">备注：</a-col>
                    <a-col :span="21">
                        <a-textarea v-model="siteInfo.remark" placeholder="请输入" :auto-size="{ minRows: 5 }" />
                    </a-col>
                </a-row>
            </a-card>

            <a-collapse activeKey="1">
                <a-collapse-panel key="1" header="高级设置">
                    <a-row>
                        <a-col :span="3" class="label-left">订单最低单价：</a-col>
                        <a-col :span="21">
                            <a-input-number :placeholder="unitPriceMin.toString()" v-model="advanced.minPrice" style="width: 280px" :precision="2">
                            </a-input-number>
                            <div class="input-number-slot">
                                <span style="padding: 0 10px">元 / {{typeText | unit}}</span>
                            </div>
                        </a-col>

                        <a-col :span="3" class="label-left">最小采购数量：</a-col>
                        <a-col :span="9">
                            <a-input-number :placeholder="infoTypeObj.value === 4 || infoTypeObj.value === 5 ? 1 : 0 ? '1' : '0'" :min="infoTypeObj.value === 4 || infoTypeObj.value === 5 ? 1 : 0" v-model="advanced.minNum" style="width: 190px" :precision="infoTypeObj.value === 4 || infoTypeObj.value === 5 ? 0 : 4" />
                            <div class="input-number-slot">
                                <span style="padding: 0 10px">{{typeText | unit}}</span>
                            </div>
                            <div class="font-error" v-if="advanced.minNum > productInfo.num">
                                <span>*最小采购数量大于货品总数量</span>
                            </div>
                            <div class="font-error" v-else-if="advanced.maxNum && advanced.minNum && (advanced.maxNum < advanced.minNum)">
                                <span>*最大采购数量小于最小采购数量</span>
                            </div>
                        </a-col>
                        <a-col :span="3" class="label-left">最大采购数量：</a-col>
                        <a-col :span="9">
                            <a-input-number :placeholder="productInfo.num ? productInfo.num.toString() : '0'" v-model="advanced.maxNum" style="width: 190px" :precision="infoTypeObj.value === 4 || infoTypeObj.value === 5 ? 0 : 4" />
                            <div class="input-number-slot">
                                <span style="padding: 0 10px">{{typeText | unit}}</span>
                            </div>
                            <div class="font-error" v-if="advanced.maxNum > productInfo.num">
                                <span>*最大采购数量大于货品总数量</span>
                            </div>
                            <div class="font-error" v-else-if="advanced.maxNum && advanced.minNum && (advanced.maxNum < advanced.minNum)">
                                <span>*最大采购数量小于最小采购数量</span>
                            </div>
                        </a-col>
                    </a-row>
                </a-collapse-panel>
            </a-collapse>

            <div class="issue-shop">
                <a-space>
                    <a-button @click="$router.replace(`/seller-content/store-manage?entityId=${$route.query.entityId}`)">返回</a-button>
                    <a-button type="primary" @click="postTrade" :loading="loading" :disabled="disabled"><a-icon type="shop" />保存并上架</a-button>
                </a-space>
            </div>
        </a-card>
        
      </div>
  </div>
</template>

<script>
import Cascader from './components/CascaderCopy.vue'
import {uploadPhoto} from '@/api/utils.js'
import SearchSelect from '@/components/SearchSelect'
import {querySecondAttr, queryCateroryName, queryRegionPolicy, editProductSave, productDetail, evaluation, saleamount, usualaddress} from '@/api/seller.js'
import options from '@/utils/ara'
import {categoryTree} from '@/api/store.js'
import {mapGetters} from 'vuex'
import { type } from "@/utils/constArr";
export default {
    components: {
        Cascader,
        SearchSelect
    },
    data() {
        return {
            taxRateList: [],
            searchList: [],
            taxObj: {netAmount: '', taxableAmount: ''},
            saleamount: {},
            options,
            // 税率
            taxRate: {
                id: '',
                policyName: '',
                vatRate: 0
            },
            // 详情
            detail: {},
            // 搜索分类条件
            term: '',
            // 分类id
            classifyId: {primary: null, re: null},
            // 分类tree
            classifyCopy: [],
            usualaddress: [], // 常用地址
            cascaderValue: [],
            // 一级二级三级分类控制
            selectClassify: {
                levelPrimary: '',
                levelBy: '',
                property: '',
            },
            // 发票信息
            invoiceInfo: {
                list: [],
                index: undefined
            },
            // 商品信息
            productInfo: {
                num: '',
                price: '',
                vat: 0,
                surcharge: 0,
                witholding: 0,
                totalPrices: 0,
                totalCostAmount: ''
            },
            // 高级设置
            advanced: {
                minPrice: '',
                minNum: '',
                maxNum: '',
            },
            // 商品信息
            infoTypeObj: {
                list: ['T','K','G','S','C'],
                value: 1
            },
            merchandiseImgList: [], // 上传的照片
            // 地址信息
            siteInfo: {
                address: '',
                remark: ''
            },
            isSpec: true, // 发票上是否显示该规格
            isSuccess: {
                show: true,
                data: ''
            },
            loading: false,
            disabled: false,
            estimateModel: 1
        }
    },
    filters: {
        unit:(val)=>type[val],
        transition(val) {
            if (val > 10000) {
              return val = (val / 10000).toFixed(2) + '（万）'
            }
            return val
        }
    },
    computed: {
        ...mapGetters(['getRoleS']),
        typeText() {
            return this.infoTypeObj.list[this.infoTypeObj.value - 1] 
        },
        // 商品单价
        unitPrice() {
            if (!this.productInfo.num || !this.productInfo.price) return 0
            return Math.round(this.productInfo.price / this.productInfo.num * 100) / 100
        },
        // 最低单价
        unitPriceMin() {
            if (!this.unitPrice) return 0
            return Math.round(95/100 * this.unitPrice * 100) / 100
        },
        totalPrices() {
            return (this.productInfo.price || 0) + this.productInfo.witholding + this.productInfo.vat
        },
        seller() {
            const obj = this.getRoleS.find(item => item.entityId == this.$route.query.entityId) || {}
            return obj.commercialName
        }
    },
    mounted() {
        saleamount(this.$route.query.entityId).then(res => {
            if (res.code === 200) {
                this.saleamount = res.data
            }
        })
        queryRegionPolicy(this.$route.query.entityId).then(res => {
            console.log(res)
            if(res.code === 200) {
                if (!res.data || !res.data.length) {
                this.$message.error('卖家未绑定税费计算政策')
                this.loading = true
                this.disabled = true
                } else {
                    this.taxRateList = res.data
                    const {id, policyName, vatRate} = res.data[0]
                    this.taxRate = {id, policyName, vatRate}
                }
                console.log('增势税', this.taxRate)
                productDetail({merchandiseId: this.$route.query.id}).then(res => {
                    console.log(res)
                    this.detail = res.data
                    this.isSpec = this.detail.isSpec // 发票上是否显示该规格
                    this.productInfo.totalCostAmount = res.data.totalCostAmount
                    this.productInfo.price = res.data.totalPrice
                    this.productInfo.num = res.data.quantity
                    this.merchandiseImgList = res.data.imgList
                    this.cascaderValue = [res.data.pCode, res.data.cCode, res.data.aCode]
                    this.siteInfo.address = res.data.addrDetail
                    this.siteInfo.remark = res.data.remark
                    this.advanced.minNum = res.data.minQuantity
                    this.advanced.maxNum = res.data.maxQuantity
                    this.advanced.minPrice = res.data.minFloatUnitPrice
                    this.infoTypeObj.value = this.infoTypeObj.list.find(item => item === res.data.unitsMeasure) ? this.infoTypeObj.list.findIndex(item => item === res.data.unitsMeasure) + 1 : 1
                    this.getTaxes()
                    this.getClassify()
                })
            } else {
                this.$message.error(res.message)
            }
        })
        usualaddress(this.$route.query.entityId).then(res => {
            if (res.code === 200) {
                this.usualaddress = res.data
            }
        })
    },
    methods: {
        // 选择一级分类时重置所有信息
        changeSwitch(e) {
            this.selectClassify.levelPrimary = e
            this.selectClassify.levelBy = ''
            this.selectClassify.property = ''
            this.invoiceInfo = {
                list: [],
                index: undefined
            }
        },
        // 选中二级分类获取属性和发票信息
        changeRe(e) {
            this.selectClassify.levelPrimary = e[0]
            this.selectClassify.levelBy = e[1]
            // 查找分类属性
            if(!e[1].id){
                return
            }
            querySecondAttr(e[1].id).then(res => {
                let data = res.data
                data.forEach((item,index) => {
                    if (item.attrValue) {
                        item.attrValue = item.attrValue.split(',')
                    }
                    item.value = index === 0 ? item.attrValue[0] :undefined
                })
                this.selectClassify.property = data
                console.log('分类属性',this.selectClassify.property)
            })
            // 查找发票信息
            queryCateroryName(e[1].id).then(res => {
                console.log('发票信息',res)
                this.invoiceInfo.list = res.data
                // 查找默认值
                if (!res.data.length) {
                    this.invoiceInfo.index = undefined
                } else {
                    const index = this.invoiceInfo.list.findIndex(item => item.isDefault)
                    this.invoiceInfo.index = index != -1 ? index : 0 
                }
            })
        },
        // 筛选二级标签
        secondSearch(e) {
            console.log(e);
            if (this.term === e) {
                this.searchList = this.searchList.map(item => {return {...item}})
            } else {
                this.term = e
            }
        },
        typeChange(e) {
            this.infoTypeObj.value = e
            this.advanced.maxNum = this.productInfo.num
            this.advanced.minNum = (this.infoTypeObj.value === 4 || this.infoTypeObj.value === 5 ? 1 : 0)
        },
        typeTaxChange(e) {
            console.log(e);
            const {id, policyName, vatRate} = this.taxRateList.find(item => item.id === e)
            this.taxRate = {id, policyName, vatRate}
            this.getTaxes()
        },
        estimateModelChange(e) {
            this.estimateModel = e
            if (!this.productInfo.price) {
                this.estimateModel = 0
            }
        },
        // 获取选中的分类
        getClassify() {
            categoryTree().then(res => {
                console.log(res)
                this.classifyCopy = res.data
                this.classifyId.primary = this.detail.categoryFirstId
                this.classifyId.re = this.detail.categorySecondId
                this.selectClassify.levelPrimary = this.classifyCopy.find(item => item.id === this.detail.categoryFirstId) || ''
                this.selectClassify.levelBy = this.selectClassify.levelPrimary.children.find(item => item.id === this.detail.categorySecondId) || ''
                querySecondAttr(this.detail.categorySecondId).then(res => {
                    let data = res.data
                    const attr = JSON.parse(this.detail.attributes)
                    console.log(attr)
                    data.forEach(item => {
                        if (item.attrValue) {
                            item.attrValue = item.attrValue.split(',')
                        }
                        item.value = attr[item.attrName] || undefined
                    })
                    this.selectClassify.property = data
                    console.log('分类属性',this.selectClassify.property)
                })
                queryCateroryName(this.detail.categorySecondId).then(res => {
                    console.log('发票信息',res)
                    this.invoiceInfo.list = res.data.length ? res.data : [{taxClassCode: this.detail.invoiceCode, taxClassName: this.detail.invoiceName}]
                    // 查找编号
                    let index = this.invoiceInfo.list.findIndex(item => item.taxClassCode + item.taxClassName === this.detail.invoiceCode + this.detail.invoiceName)
                    if (index === -1) {
                        // 查找编号失败时 设置disabled
                        this.invoiceInfo.list = [{taxClassCode: this.detail.invoiceCode, taxClassName: this.detail.invoiceName,disabled: true}, ...this.invoiceInfo.list]
                        index = 0
                    }
                    this.invoiceInfo.index = index
                })
            })
        },
        async computeBlur () {
            await this.getTaxes()
            await this.quota()
        },
        async quota () {
            if (this.estimateModel === 1 && this.productInfo.price > this.saleamount.netAmountLimit) {
                this.$message.error('货物总价金额大于450万限额');
            } else if (this.estimateModel === 2 && this.productInfo.price > this.saleamount.contractAmountLimit) {
                this.$message.error('价税合计金额大于450万限额');
            } else if ((this.infoTypeObj.value === 4 || this.infoTypeObj.value === 5) && ((this.estimateModel === 2 ? this.taxObj.netAmount : this.productInfo.price) > 98000)) {
                console.log(this.taxObj.netAmount, this.productInfo.price, (this.estimateModel === 2 ? this.taxObj.netAmount : this.productInfo.price) > 98000);
                this.$message.error('货物总价金额大于9.8万');
            }
        },
        async getTaxes() {
            try {
                if (!this.productInfo.num || !this.productInfo.price) return
                const res = await evaluation(this.$route.query.entityId, this.productInfo.price, this.taxRate.id, this.estimateModel === 2 ? 'contractAmount' : 'netAmount')
                const result = res.data[0] || {}
                this.productInfo.vat = result.vatAmount
                this.productInfo.witholding = result.taxAmount
                this.productInfo.totalPrices = result.contractAmount
                this.taxObj = {netAmount: result.netAmount, taxableAmount: result.taxableAmount}
            } catch (err) {
                console.log(err);
            }
        },
        addItem(key) {
            this[key].form.push(this[key].addItem)
            this[key].value = this[key].addItem
            this[key].addItem=''
        },
        // 选择发货地
        cascaderChange(e) {
            this.cascaderValue = e
            this.siteInfo.address = ''
        },
        // 设置主图
        isMain(index, b) {
            if (b) {
                // 取消主图
                this.merchandiseImgList[index].isMain = 0
            } else {
                // 设置主图
                this.merchandiseImgList.forEach((item,i) => {
                    item.isMain = 0
                    if (index == i) {
                        item.isMain = 1
                    }
                })
            }
        },
        // 删除图片
        delImg(index) {
            // this.merchandiseImgList.splice(index,1)
            if (typeof this.merchandiseImgList[index].delFlag === 'number') {
                this.merchandiseImgList[index].delFlag = 1
            } else {
                this.merchandiseImgList.splice(index,1)
            }
        },
        // 获取上传的img文件
        getImgFile(e) {
            if (e.target.files[0]) {
            if (e.target.files[0].type.indexOf('image') === -1) {
                this.$message.error('请上传正确的格式')
                e.target.value = null
                return
            }
            this.uploadImage(Array.from(e.target.files))
            console.log('上传的照片',this.merchandiseImgList)
            e.target.value=null
            }
        },
        // 选择图片
        selectImg() {
            this.$refs.addImage.dispatchEvent(new MouseEvent('click')) 
        },
        selectAddress(e) {
            const data = this.usualaddress.find(item => item.addrFull == e)
            this.cascaderValue = [data.provinceCode, data.cityCode, data.areaCode]
            const list = data.addrFull.split(' ')
            let address = ''
            list.forEach((item,index) => {
                if (index > 2) {
                    address = address + item
                }
            })
            this.siteInfo.address = address
        },
        // 上传图片
        uploadImage(images){
            const file = images[0]
            uploadPhoto(file, (url, imgName) => {
                this.merchandiseImgList.push({imgPath: url, imgName, isMain: 0,sortNo:images.length})
                images.splice(0, 1);
                if (images.length > 0) {
                    this.uploadImage(images)
                }
            })
		},
        postTrade() {
            if (!this.$route.query.entityId) {
                this.$message.error('缺少供应商ID'); return
            } else if (!this.selectClassify.levelPrimary.id || !this.selectClassify.levelPrimary.catValue) {
                this.$message.error('缺少一级分类参数'); return
            } else if (!this.selectClassify.levelBy.id || !this.selectClassify.levelBy.catValue) {
                this.$message.error('缺少二级分类参数'); return
            } else if (!this.selectClassify.property.length) {
                this.$message.error('缺少分类属性参数'); return
            } else if (!this.selectClassify.property[0].value) {
                this.$message.error('请填写规格型号'); return
            } else if (!this.invoiceInfo.index && this.invoiceInfo.index!==0) {
                this.$message.error('请选择品类'); return
            } else if (!this.productInfo.num || this.productInfo.num === 0) {
                this.$message.error('请填写货品数量'); return
            } else if (!this.productInfo.price || this.productInfo.price === 0) {
                this.$message.error('请填写货物总价'); return
            } else if (this.productInfo.price*0.96 < this.productInfo.totalCostAmount) {
                this.$message.error(`商品成本需小于或等于商品总价的的96%,商品成本限额：${(this.productInfo.price*0.96).toFixed(2)}`); return
            } else if ((this.infoTypeObj.value === 4 || this.infoTypeObj.value === 5) && ((this.estimateModel === 2 ? this.taxObj.netAmount : this.productInfo.price) > 98000)) {
                this.$message.error('商品数量单位为台、件，货物总价不允许大于9.8万'); return
            } else if (this.estimateModel === 2 ? this.taxObj.netAmount > this.saleamount.netAmountLimit: this.productInfo.price > this.saleamount.netAmountLimit) {
                this.$message.error('货物总价大于450万限额'); return
            } else if (this.advanced.minNum && (this.advanced.minNum > this.productInfo.num)) {
                this.$message.error('最小采购数量大于货品总数量'); return
            } else if (this.advanced.maxNum && (this.advanced.maxNum > this.productInfo.num)) {
                this.$message.error('最大采购数量大于货品总数量'); return
            } else if (this.advanced.maxNum && this.advanced.minNum && (this.advanced.maxNum < this.advanced.minNum)) {
                this.$message.error('最大采购数量不能小于最小采购数量'); return
            } if (!this.cascaderValue.length) {
                this.$message.error('请填写发货地'); return
            }
            // 转换二级分类属性参数
            const attributes = {}
            this.selectClassify.property.forEach(item => {
                attributes[item.attrName] = item.value === 'No_choice' ? null : item.value
            })
            const config = {
                merchandiseId: this.$route.query.id, // 商品id
                categorySecondId: this.selectClassify.levelBy.id, // 二级分类id
                categorySecondTaxCodeId: this.invoiceInfo.list[this.invoiceInfo.index].id,
                attributes: JSON.stringify(attributes), // 二级分类属性
                quantity: this.productInfo.num, // 商品数量
                areaCode: this.cascaderValue[2], // 区code
                addrDetail: this.siteInfo.address, // 详细地址
                totalPrice: this.estimateModel === 2 ? this.taxObj.netAmount : this.productInfo.price, // 商品总价
                isSpec: this.isSpec, // 是否显示规格(默认false-不显示，true-显示)
                specName: this.selectClassify.property.length && this.selectClassify.property[0].value || ' ', // 商品规格（可以勾选不显示规格，则用空格代替）
                maxQuantity: this.advanced.maxNum || this.productInfo.num, // 最大采购量
                minQuantity: this.advanced.minNum || (this.infoTypeObj.value === 4 || this.infoTypeObj.value === 5 ? 1 : 0),
                minFloatUnitPrice: this.advanced.minPrice, // 最低浮动单价
                unitsMeasure: this.infoTypeObj.list[this.infoTypeObj.value -1], // 计量单位
                remark: this.siteInfo.remark, // 备注
                merchandiseImgList: this.merchandiseImgList.length && this.merchandiseImgList || undefined, // 图片
                totalCostAmount: this.productInfo.totalCostAmount
            }
            console.log(config)
            this.loading = true
            editProductSave(config).then(res => {
                console.log(res)
                this.loading = false
                if (res.code === 200) {
                    this.$message.success(res.message)
                    this.isSuccess.show = false
                    window.scrollTo(0,0)
                    this.isSuccess.data = res.data
                    this.$router.replace(`/seller-content/store-manage?entityId=${this.$route.query.entityId}`)
                } else {
                    this.$message.error(res.message)
                }
            }).catch(()=>{
                    this.loading = false
            })
        },
        numChange() {
            this.advanced.maxNum = this.productInfo.num
            this.advanced.minNum = (this.infoTypeObj.value === 4 || this.infoTypeObj.value === 5 ? 1 : 0)
        }
    }
}
</script>

<style lang="less" scoped>
.add-product-container {
    margin: 0 auto;
    max-width: 1440px;
    min-width: 990px;
    font-size: 16px;
    .container-title {
        margin: auto;
        width: 1100px;
        @media screen and (max-width: 1440px){
            width: 100%;
            max-width: 1440px;
            min-width: 990px;
            padding: 0 15px;
        }
    }
    .container {
        font-size: 14px;
        margin-bottom: 32px;
        display: flex;
        justify-content: center;
        .add-content {
            width: 1100px;
            @media screen and (max-width: 1440px){
                width: 100%;
                max-width: 1440px;
                min-width: 990px;
                margin: 0 15px;
            }
            .content {
                /deep/ .ant-col {
                    line-height: 32px;
                }
                .right {
                    text-align: right;
                    line-height: 32px;
                }
            }
            /deep/ .ant-card-head {
                background: #e9e9e9;
            }
            /deep/ .ant-col {
                margin-bottom: 12px;
            }
            .label-left {
                line-height: 32px;
                text-align: right;
            }
            .label-c {
                line-height: 32px;
                text-align: center;
            }
            /deep/ .ant-alert-with-description.ant-alert-no-icon {
                padding: 6px 16px;
            }
            /deep/ .ant-card {
                margin-bottom: 20px;
            }
            .l-card {
                position: relative;
                .title {
                    content: '分类属性';
                    position: absolute;
                    top: -12px;
                    left: 16px;
                    background: #fff;
                    z-index: 1;
                    font-weight: bold;
                    padding: 0 12px;
                    color: #000;
                }
            }
            .add-property-item {
                width: 100%;
                height: 100%;
            }
            .l-flex {
                flex-wrap: wrap;
            }
            .add-image {
                height: 135px;
                user-select:none;
                cursor: pointer;
                text-align: center;
                i {
                    color: #cccccc;
                    line-height: 135px;
                    font-size: 42px;
                }
            }
            .product-img {
                border: 1px solid #e4e4e4;
                width: 150px;
                min-height: 135px;
                margin-right: 20px;
                margin-bottom: 20px;
                .img-config {
                    text-align: center;
                    border-top: 1px solid #e4e4e4;
                    background: #f9fafc;
                    line-height: 36px;
                    font-size: 12px;
                }
                img {
                    width: 100%;
                    height: 135px;
                }
                // &:nth-child(-n+5) {
                //     margin-top: 0;
                // }
            }
            .advanced {
                padding: 0 16px;
            }
            .issue-shop {
                margin: 32px 0;
                text-align: center;
                button {
                    width: 148px;
                    font-weight: bold;
                }
            }
        }
        .help-content {
            width: 270px;
            margin-left: 20px;
        }
    }
}
/deep/ .ant-input-number {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.input-number-slot {
    background: #f2f2f2;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    vertical-align: top;
    // padding: 0 8px;
    border: 1px solid #d9d9d9;
    border-left: none;
    border-radius: @border-radius-base;
    /deep/ .ant-select-selection {
        background-color: transparent;
        border: none;
    }
    /deep/ .ant-select-open .ant-select-selection {
        border: none;
        box-shadow: none;
    }
    /deep/ .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
        border: none;
        box-shadow: none;
    }
}
.no-show-input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
/deep/ .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 30px;
}
.font-error {
    color: @error;
}
.is-main {
    border: 1px solid @error !important;
}
.font-message{
    color: @error;
    top: -5px;
    position: relative;
    left: 10px;
    font-size: 12px;
}
</style>